import React, { useReducer } from "react";
import Real from "./Pages/Real";
import Demo from "./Pages/Demo";
import DemoRunning from "./Pages/DemoRunning";
import Withdrawal from "./Pages/Withdrawal";
import WithdrawalHistory from "./Pages/WithdrawalHistory";
import DepositHistory from "./Pages/DepositHistory";
import TransactionHistoryt from "./Pages/TransactionHistoryt";
import Security from "./Pages/Security";
import PersonaData from "./Pages/PersonaData";
import ProcessFunds from "./Pages/ProcessFunds";
import OpenOders from "./Pages/OpenOders";
import Deposit from "./Pages/Deposit";
import RoboticSoftware from "./Pages/Roboticsoftware";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import Home from "./Pages/Home";

function App() {
  return (
    <>
      <GlobalState>
        <BrowserRouter>
          < Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/real" element={<Real />} />
            <Route exact path="/demo" element={<Demo />} />
            <Route exact path="/demorunnig" element={<DemoRunning />} />
            <Route path="/deposit" element={<Deposit />} />
            <Route path="/depositprocessing" element={<ProcessFunds />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/security" element={<Security />} />
            <Route path="/profile" element={<PersonaData />} />
            <Route path="/roboticsoftware" element={<RoboticSoftware />} />
            <Route path="/transactionhistory" element={<TransactionHistoryt />} />
            <Route path="/withdrawalhistory" element={<WithdrawalHistory />} />
            <Route path="/deposithistory" element={<DepositHistory />} />
            <Route path="/openoders" element={<OpenOders />} />
          </ Routes>
        </BrowserRouter>
      </GlobalState>
    </>
  );
}

export default App;
