import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Browsernav from '../Components/Browsernav';
import Mobilenav from '../Components/Mobilenav';
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import Swal from 'sweetalert2'
import ScriptTag from "react-script-tag";

function Security() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [password, setpassword] = useState("")
    const [password2, setpassword2] = useState("")

    const changepassword = () => {

        if (password == "") {
            Swal.fire(
                'No Selected Passowrd?',
                'Please Select A Valid Password',
            )
            return

        }

        if (password != password2) {
            Swal.fire(
                'Password does not match?',
                'Password must match',
            )
            return
        }
        setloading(true)
        var user = f.auth().currentUser;
        user
            .updatePassword(password)
            .then(function () {
                setloading(false)
                Swal.fire({
                    icon: 'success',
                    title: 'password changed',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                console.log(error)
                setloading(false)
                Swal.fire(
                    'Something went wrong',
                    `${error.message}`,
                )

            });
    };

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };
    return (
        <div>
            <Helmet>
                <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <title> GLOBALFOXTRADE Main </title>
                <meta name="description" content="            
														" />

                <link rel="shortcut icon" type="image/png" sizes="32x32" href="assets/img/favicon.png" />
                <link rel="shortcut icon" type="image/png" sizes="16x16" href="assets/img/favicon.png" />
                <link rel="canonical" href="dashboard" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
                <link href="/dashboard/assets/css/css.css" rel="stylesheet" type="text/css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/inner.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/app.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/cs.css" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweetalert.min.js" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweet-alert.css" />
                <script async="" src="//dashboard/assets/js/js.js"></script>
                <script type="text/javascript" src="//dashboard/assets/js/conversion.js"></script>
                <script type="text/javascript" src="/dashboard/assets/js/sweet-alert.js"></script>
                <script type="text/javascript" async="" src="https://ustocktradebits.com/dashboard/assets/js/conversion_async.js"></script>

                <script src="/dashboard/assets/inner.js"></script>
                <script src="/js/app.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/watch.js"></script>
                <script async="" src="//dashboard/assets/js/analytics.js"></script>
                <script src="/dashboard/assets/inner.js"></script>
                <script src="/dashboard/assets/js/vendor.js"></script>
                <script src="/dashboard/assets/app.js"></script>

                <script type="text/javascript" src="validation.min.js"></script>
                <script type="text/javascript" src="trade_script.js"></script>
                <script type="text/javascript" src="trade_script2.js"></script>
                <script src="/js/sweet-alert.js"></script>
                <script src="https://use.fontawesome.com/4b789087e7.js"></script>
            </Helmet>

            <Mobilenav />
            <div id="google_translate_element"></div>
            <ScriptTag type="text/javascript">
                {`

                                  function googleTranslateElementInit() {
                                  new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                                    }
                                     `}
            </ScriptTag>
            <ScriptTag
                type="text/javascript"
                src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            ></ScriptTag>
            <div className="pusher" aria-hidden="false">
                <nav className="top-nav inner inner-new">
                    <div className="top-bar" style={{ height: '50px' }}>
                        <div className="head-row clearfix">
                            <div className="float-left ">
                                <div className="logosm">
                                    <a href="dashboard.php"><img src="assets/img/toplogo.png" alt="ProvidusOption" /></a>
                                </div>
                                {/*<a href="" class="top-bar-nav dinb"><i class="help-open"></i> <span>Support</span></a>*/}
                                <div className="ui item dropdown dropdown-call dinb" tabIndex={0}>
                                    <div className="top-bar-nav">
                                        <div className="phone-open" />
                                    </div>
                                    <div className="menu transition hidden" tabIndex={-1}>
                                        <div className="item">
                                            <div className="phone-notice">Please input your phone number with<br />country code and we will immediately contact you.</div>
                                            <div>+<input className="call-input" placeholder="phone" type="text" maxLength={20} force-integer id="callback" style={{ width: '200px' }} /><button className="button ui green-bm" id="call_btn" onclick="callBack()">Call back</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="menu-toggle float-right"><i /></span>
                            <div className="float-right nav smcapy-nav">
                                <div className="ui item lang pointing dropdown" tabIndex={0}>
                                    <input type="hidden" name="lang" />
                                    <div className="default text">
                                        <div id="google_translate_element" className="google-trans" />
                                    </div>
                                    <i className="dropdown icon" />
                                </div>
                                <div className="item"><span style={{ color: '#eee' }}>Account Status:</span>
                                    <span style={{ color: '#0f0' }} className="blinkgreen"><i className="fa fa-check-circle" /> Active</span>
                                </div>
                                <div className="item"><a className="ui button op link a-green-hover" href="logout.php"><i className="fa fa-power-off" /> Exit</a></div>
                                <div className="item"><a className="ui button op nobold" style={{ background: '#07b18c' }} href="/deposit"> Account Funding</a></div>
                                {/*<div class="item"><a class="ui button op nobold" style="background:#E95524" href="/deposit"><i class="fa fa-money"></i> Demo Account 0.00 USD</a></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="top-line clearfix">
                        <div className="row-30">
                            <ul className="icon-nav">
                                <li className="active"><a target="_blank" title="Charting tools" className="technic" href="chart.php" style={{ color: '#FFFFFF' }}><i className="fa fa-bar-chart" /></a></li>
                                <li className="active"><a title="Fundamental analysis" className="fundament" href="fundamental-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-pie-chart" /></a></li>
                                <li className="active"><a title="News" className="expert" href="news.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-tv" /></a></li>
                                <li className="active"><a title="Technical Analysis" className="ideas" href="technical-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-signal" /></a></li>
                                <li className="active"><a title="Economic calendar" className="calendar" href="calendar.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-calendar" /></a></li>
                            </ul>
                        </div>
                        <Browsernav />
                    </div>
                </nav>
                <div className="root-content">
                    <div className="pusher push-trading">
                        {/* uiView:  */}<div style={{}}><div className="pusher pusher-min-400">
                            <section className="img-bg-section">
                                <div className="row">
                                    <ul className="tabs">
                                        <li><a href="personal-data.php">Personal Data</a></li>
                                        <li><a href="#" className="active">Security Settings</a></li>
                                    </ul>
                                    <div className="mob-tab-nav mob-main-tabs">
                                        <div className="ui not_select dropdown mob-tabular" tabIndex={0}>
                                            <div className="text default">Security Settings</div>
                                            <i className="dropdown icon" />
                                            <div className="menu" tabIndex={-1}>
                                                <a href="personal-data.php" className="item">Personal Data</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="title">Security Settings</h2>
                                </div>
                                <span className="blue-arrow" />
                            </section>
                            <div style={{}}>
                                <section className="content-box">
                                    <div className="row">
                                        <div className="form-row account-data form-hailight clearfix" style={{ borderRadius: '10px', backgroundColor: '#232835', padding: '5px 10px' }}>
                                            <div align="center"><p className="p" /></div>
                                            <form >
                                                <div className="line" />
                                                <div className="line">
                                                    <label>Current password:</label>
                                                    <input type="password" name="current" />
                                                </div>
                                                <div className="line">
                                                    <label>New password:</label>
                                                    <input type="password" required name="password" onChange={(e) => { setpassword(e.target.value) }} />
                                                </div>
                                                <div className="line">
                                                    <label>Confirm new password:</label>
                                                    <input type="password" required name="password2" onChange={(e) => { setpassword2(e.target.value) }} />
                                                    <span id="passcheck" />
                                                </div>

                                            </form>
                                            <button className="ui button primal float-right" name="update_pass_btn" onClick={changepassword}>Save</button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="row clearfix">
                        <div className="float-left">
                            <span className="copy">© 2022 <span data-company>GLOBALFOXTRADE</span>. <span data-company-address>The Stables, Levens Hall LA8 0PB, England, UK</span></span> <a href="/en/policies">Privacy policy</a>
                        </div>
                        <div className="float-right">
                            <ul className="soc-list">
                                <li>Follow us</li>
                                {/*<li>
                    	<div class="footer-media">
		                    <div class="media-icons">
		                        <a href="https://www.facebook.com/GLOBALFOXTRADE"><span class="icon-mso-fb"></span></a>
		                    </div>
		                </div>
                    </li>*/}
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-instagram" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-twitter-square" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                {/*<li><a href="https://www.linkedin.com/company/GLOBALFOXTRADE" class="soc_li"></a></li> 
                    <li><a href="https://twitter.com/GLOBALFOXTRADECom" class="soc_tw"></a></li>
                    <li><a href="https://www.facebook.com/GLOBALFOXTRADECom" class="soc_fb"></a></li>*/}
                                {/*<li><a href="" class="soc_yt"></a></li>*/}
                            </ul>
                        </div>
                    </div>
                </footer>	</div>
            {/* Yandex.Metrika counter */}

            {/* Global site tag (gtag.js) - Google AdWords: 824992907 */}
            {/*StartofTawk.toScript*/}
            {/*End of Tawk.to Script*/}
        </div>
    )
}

export default Security