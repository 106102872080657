import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Browsernav from '../Components/Browsernav';
import Mobilenav from '../Components/Mobilenav';
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import Swal from 'sweetalert2'
import ScriptTag from "react-script-tag";

function RoboticSoftware() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const ActivateRobots = () => {
        Swal.fire({
            title: 'The auto trading bot can only be activated by the bot department, message us Via Live support to activate Robots ',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })


        history("/deposit")
    }
    return (
        <div>
            <Helmet>
                {/* StyleSheets  */}
                <link rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/assets/css/dashlite.php?ver=3.0.3" />
                <link id="skin-default" rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/assets/css/theme.css?ver=3.0.3" />
                <link rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/assets/css/libs/fontawesome-icons.css?ver=3.0.3" />
                <link rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/assets/css/libs/themify-icons.css?ver=3.0.3" />
                <link rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/assets/css/libs/bootstrap-icons.css?ver=3.0.3" />
                <link rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/css/color.php?color=ff1010&secondColor=1fb7c1" />
                {/* StyleSheets  */}
                <link rel="stylesheet" href="https://lucids-fx.com/trade/assets/templates/basic/assets/css/vest.css?ver=3.0.3" />
            </Helmet>

            {/* Smartsupp Live Chat script */}
            {/* <Mobilenav />
            <Browsernav/> */}
            <div className="nk-app-root">
           
                {/* main @s  */}
                <div className="nk-main ">
                    {/* wrap @s  */}
                    <div className="nk-wrap ">
                        {/* main header @s  */}
                       
                        {/* main header @e  */}
                      
                        {/* content @s  */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div className="card card-bordered card-preview">
                                            <div className="card-inner">
                                                <nav>
                                                    <ul className="breadcrumb breadcrumb-arrow">
                                                        <li className="breadcrumb-item"><a href="https://lucids-fx.com/trade/user/dashboard">Home</a></li>
                                                        <li className="breadcrumb-item active">New Trade</li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>{/* .card-preview */}
                                        <br />
                                        <div className="alert alert-icon alert-primary" role="alert">
                                            <em className="icon ni ni-alert-circle" />
                                            <strong>Welcome to the Market! </strong>Here you can purchase various automated trading bots ( ROBOTIC TRADING SOFTWARE ), Which gives you 99% assurance of winning almost all your trades and earn more profit without much stress.
                                        </div>
                                        <img onClick={ActivateRobots} src="https://lucids-fx.com/bot.png" alt="Girl in a jacket" width="auto" height="auto" />
                                        <br />
                                        <br />
                                        <div className="alert alert-icon alert-primary" role="alert">
                                            <em className="icon ni ni-alert-circle" />
                                            <strong>What to do? </strong>Tap on the live chat icon below and and a customer representative will guild you on how to use and order our automated trading bots ( ROBOTIC TRADING SOFTWARE ).
                                        </div>
                                        <p>All materials and services provided on this site are subject to copyright and belong to "GLOBALFOXTRADE". Any use of materials of this website must be approved by an official representative of "GLOBALFOXTRADE", and contain a link to the original resource. Any third-party companies of "Online broker" or "Online trading" type, do not have the right to use materials of this website as well as any distorted writing of "GLOBALFOXTRADE". In case of violation, they will be prosecuted in accordance with legislation of the intellectual property protection.
                                            GLOBALFOXTRADE does not provide service to residents of Israel.
                                        </p>
                                      
                                    </div>{/* .nk-iv-wg4 */}
                                </div>{/* .card */}
                            </div>{/* .col */}
                        </div>{/* .row */}
                    </div>{/* .nk-block */}
                </div>
            </div>
            {/* .card-preview */}
            {/* content @e  */}
            {
                loading && (
                    <div class="preloader js-preloader">
                        <div class="loader loader-inner-1">
                            <div class="loader loader-inner-2">
                                <div class="loader loader-inner-3">
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default RoboticSoftware