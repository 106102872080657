import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Browsernav from '../Components/Browsernav';
import Mobilenav from '../Components/Mobilenav';
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";
import ScriptTag from "react-script-tag";

function Withdrawal() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [coinselected, setcoinselected] = useState("btc")
    const [amount, setamount] = useState("")
    const [wallet, setwallet] = useState("")
    const [loading, setloading] = useState(true)

    const updateUserBalance = async (bal) => {
        const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef
            .update({
                balance: newEarings,
            })
            .then(function () {
                console.log("Document successfully up2dated!");
                // alert("withdrawal was successful we will get back to you");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata();
    };

    const updatewithdrawalhistory = async () => {
        const newBal = parseFloat(userdetails.balance); /*- parseFloat(amount);*/
        const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
                amt: amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coinselected,
                status: "pending"

            }),
        });
    };

    const sendAdminMaiil = async () => {
        var templateParams = {
            message: `${userdetails.email} have successfully withdrawan  $${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  check admin dashboard to verify `,
        };

        // setloading(false)
        //handleClick1()

        await emailjs
            .send(
                "service_oygvfwj",
                "template_c3s21n6",
                templateParams,
                "BSID0Qyf_bTBAHW2E"
            )
            .then(
                function (response) {
                    // setloading(false)
                },
                function (err) {
                    // setloading(false)
                    alert("FAILED...", err);
                }
            );
    }
    const Withdrawal = async (usermail) => {
        let d = new Date()
        let n = d.toString()

        setloading(true)
        await updatewithdrawalhistory()
        await updateUserBalance()
        sendAdminMaiil()

        setloading(false)
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Withdrwal has been Approved',
            showConfirmButton: false,
            timer: 1500
        })

        history("/real");

    };


    function Confirmwithdrawal() {
        if (amount == "") {
            Swal.fire(
                'No Selected Amount?',
                'Please Select Amount To Withdraw?',
            )
            return
        }

        Swal.fire({
            title: 'Confirmation',
            text: `do you want to withdraw ${amount} worth of ${coinselected} to ${wallet}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (parseFloat(userdetails.balance) < parseFloat(amount)) {
                    Swal.fire(
                        'balance not enough',
                        'Please Select Valid Amount?',
                    )
                } else {
                    setloading(true)
                    Withdrawal(userdetails.email)
                }
            }
        })

        // if (!userdetails.verified) {
        //     // alert("upload your documents for verification to complete withdrawal")
        //     return
        // }

    }

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };
    return (
        <div>
            <Helmet>
            <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="description" content=" " />
                <link rel="shortcut icon" type="image/png" sizes="32x32" href="assets/img/favicon.png" />
                <link rel="shortcut icon" type="image/png" sizes="16x16" href="assets/img/favicon.png" />
                <link rel="canonical" href="dashboard" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
                <link href="/dashboard/assets/css/css.css" rel="stylesheet" type="text/css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/inner.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/app.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/cs.css" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweetalert.min.js" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweet-alert.css" />
                <script async="" src="//dashboard/assets/js/js.js"></script>
                <script type="text/javascript" src="//dashboard/assets/js/conversion.js"></script>
                <script type="text/javascript" src="/dashboard/assets/js/sweet-alert.js"></script>
                <script src="https://dreamworldexchange.com/assets/js/min/vendor.min9654.js?v=1623946007"></script>
                <script src="https://dreamworldexchange.com/assets/js/min/app.min9654.js?v=1623946007"></script>
                <script src="https://dreamworldexchange.com/assets/js/themes/inner/min/inner.min9654.js?v=1623946007"></script>
        
                <script src="/js/app.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/watch.js"></script>
                <script async="" src="//dashboard/assets/js/analytics.js"></script>
                <script src="/dashboard/assets/inner.js"></script>
                <script src="/dashboard/assets/js/vendor.js"></script>
                <script src="/dashboard/assets/app.js"></script>

                <script type="text/javascript" src="validation.min.js"></script>
                <script type="text/javascript" src="trade_script.js"></script>
                <script type="text/javascript" src="trade_script2.js"></script>
                <script src="/js/sweet-alert.js"></script>
                <script src="https://use.fontawesome.com/4b789087e7.js"></script>
            </Helmet>
            <Mobilenav />
            <div id="google_translate_element"></div>
            <ScriptTag type="text/javascript">
                {`

                                  function googleTranslateElementInit() {
                                  new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                                    }
                                     `}
            </ScriptTag>
            <ScriptTag
                type="text/javascript"
                src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            ></ScriptTag>
            <div className="pusher" aria-hidden="false">
                <nav className="top-nav inner inner-new">
                    <div className="top-bar" style={{ height: '50px' }}>
                        <div className="head-row clearfix">
                            <div className="float-left ">
                                <div className="logosm">
                                    <a href="dashboard.php"><img src="assets/img/toplogo.png" alt="ProvidusOption" /></a>
                                </div>
                                {/*<a href="" class="top-bar-nav dinb"><i class="help-open"></i> <span>Support</span></a>*/}
                                <div className="ui item dropdown dropdown-call dinb" tabIndex={0}>
                                    <div className="top-bar-nav">
                                        <div className="phone-open" />
                                    </div>
                                    <div className="menu transition hidden" tabIndex={-1}>
                                        <div className="item">
                                            <div className="phone-notice">Please input your phone number with<br />country code and we will immediately contact you.</div>
                                            <div>+<input className="call-input" placeholder="phone" type="text" maxLength={20} force-integer id="callback" style={{ width: '200px' }} /><button className="button ui green-bm" id="call_btn" onclick="callBack()">Call back</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="menu-toggle float-right"><i /></span>
                            <div className="float-right nav smcapy-nav">
                                <div className="ui item lang pointing dropdown" tabIndex={0}>
                                    <input type="hidden" name="lang" />
                                    <div className="default text">
                                        <div id="google_translate_element" className="google-trans" />
                                    </div>
                                    <i className="dropdown icon" />
                                </div>
                                <div className="item"><span style={{ color: '#eee' }}>Account Status:</span>
                                    <span style={{ color: '#0f0' }} className="blinkgreen"><i className="fa fa-check-circle" /> Active</span>
                                </div>
                                <div className="item"><a className="ui button op link a-green-hover" href="logout.php"><i className="fa fa-power-off" /> Exit</a></div>
                                <div className="item"><a className="ui button op nobold" style={{ background: '#07b18c' }} href="/deposit"> Account Funding</a></div>
                                {/*<div class="item"><a class="ui button op nobold" style="background:#E95524" href="/deposit"><i class="fa fa-money"></i> Demo Account {userdetails.balance} USD</a></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="top-line clearfix">
                        <div className="row-30">
                            <ul className="icon-nav">
                                <li className="active"><a target="_blank" title="Charting tools" className="technic" href="chart.php" style={{ color: '#FFFFFF' }}><i className="fa fa-bar-chart" /></a></li>
                                <li className="active"><a title="Fundamental analysis" className="fundament" href="fundamental-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-pie-chart" /></a></li>
                                <li className="active"><a title="News" className="expert" href="news.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-tv" /></a></li>
                                <li className="active"><a title="Technical Analysis" className="ideas" href="technical-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-signal" /></a></li>
                                <li className="active"><a title="Economic calendar" className="calendar" href="calendar.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-calendar" /></a></li>
                            </ul>
                        </div>
                        <Browsernav />
                    </div>
                </nav>
                <div className="root-content">
                    <div className="pusher push-trading">
                        {/* uiView:  */}<div><div className="pusher pusher-min-400">
                            <section className="img-bg-section">
                                <div className="row">
                                    <ul className="tabs ng-scope" ng-controller="Menu">
                                        <li><a >Account Funding</a></li>
                                        <li><a className="active">Withdrawals</a></li>
                                    </ul>
                                    <div className="mob-tab-nav mob-main-tabs">
                                        <div className="ui not_select dropdown mob-tabular">
                                            <div className="text default">Withdrawals</div>
                                            <i className="dropdown icon" />
                                            <div className="menu">
                                                <a className="item">Account Funding</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="top-info">
                                        <h2 className="title">Your available balance is: <span style={{ color: '#2597c7' }}>{userdetails.balance} USD</span></h2>
                                    </div>
                                    <div align="center"><p className="p" /></div>
                                </div>
                                <span className="blue-arrow" />
                            </section>
                            <div>
                                <section className="content-box wd-area-box">
                                    <div className="row">
                                        <div className="wallet-drop-func" data-ng-func style={{ height: '70px' }}>
                                            <div className="ui dropdown amount-dropdown" tabIndex={0}>
                                                <div className="text"><div className="amount-item">
                                                    <div className="amount-val"><b>{userdetails.balance} </b> <b ng-bind="w.currency" className="ng-binding">USD</b></div>
                                                    <span className="amount-net">Net Balance: <b>0</b> <b ng-bind="w.currency" className="ng-binding">USD</b></span>
                                                </div></div>
                                                <i className="dropdown icon" tabIndex={0}><div className="menu" tabIndex={-1} /></i>
                                                <div className="menu" tabIndex={-1}>
                                                    {/* ngRepeat: w in info.balances */}<div className="item active" role="button" tabIndex={0} style={{}}>
                                                        <div className="amount-item">
                                                            <span className="amount-id">
                                                                <div className="amount-val"><b>{userdetails.balance} </b> <b>USD</b></div>
                                                                <span className="amount-net">Net Balance: <b>{userdetails.balance}</b> <b>USD</b></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui bottom attached tab segment active" data-tab="trading-account">
                                            <div className="wd-warn"><b>Important:</b> Chosen withdrawal method should correspond with method used for deposit.</div>
                                            <h2 className="title-wd-balance">Your available balance is:
                                                <span className="balance">
                                                    <span>{userdetails.balance} USD</span>
                                                </span>
                                            </h2>
                                            <div className="ui top attached tabular menu pay-tabs" style={{ marginTop: '20px' }}>
                                                <span className="item recomended active" data-tab="nine" role="button" onClick={() => { setcoinselected("BITCOIN") }} tabIndex={0}><span className="recomended-label"><span style={{ color: '#fff' }}>recommended</span></span><i className="select-bitcoin" /><img src="../assets/btc.png" /><br />Bitcoin</span>
                                                <span className="item" data-tab="third" role="button" onClick={() => { setcoinselected("Usdt TRC 20") }} tabIndex={0}><i className="select-litecoin" /><img src="../assets/usdt.png" /><br />USDT TRC 20</span>
                                                <span className="item" data-tab="five" role="button" onClick={() => { setcoinselected("ETHEREUM") }} tabIndex={0}><i className="select-okpay" /><img src="../assets/eth.png" /><br />Ethereum</span>
                                            </div>
                                            <div className="mob-tab-nav tabular">
                                                <div className="ui select dropdown mob-tabular">
                                                    <div className="text default">Bitcoin</div>
                                                    <i className="dropdown icon" />
                                                    <ul className="menu">
                                                        <li className="item" data-tab="nine" role="button" onClick={() => { setcoinselected("BITCOIN") }} tabIndex={0}>Bitcoin</li>
                                                        <li className="item" data-tab="third" role="button" onClick={() => { setcoinselected("Usdt TRC 20") }} tabIndex={0}>USDT TRC 20</li>
                                                        <li className="item" data-tab="five" role="button" onClick={() => { setcoinselected("ETHEREUM") }} tabIndex={0}>Ethereum</li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="pay-tabs-content">
                                                <div className="ui bottom attached tab segment" data-tab="third">
                                                    <p className="info-selected-pay">To request for withdrawal to <b>USDT wallet</b>, please make at least one trading deposit by using selected method.</p>
                                                    <form >
                                                        <div id="error" style={{ fontSize: 'small', color: '#FF0000' }} />
                                                        <label>
                                                            <span>Withdrawal amount: </span>
                                                            <span>USD</span>
                                                            <input type="text" required name="w_amount" onChange={(e) => { setamount(e.target.value) }} placeholder={0.00} size={20} id="amount" style={{ minWidth: '150px' }} onkeypress="javascript:return isNumber(event)" /></label>
                                                        <input type="hidden" required name="method" defaultValue="Litecoin" size={20} style={{ minWidth: '150px' }} />
                                                        <div style={{ height: '10px' }} />
                                                        <label>
                                                            <span>USDT TRC 20 wallet: </span>
                                                            <input type="text" required name="w_address" size={40} onChange={(e) => { setwallet(e.target.value) }} style={{ minWidth: '200px' }} />
                                                        </label>
                                                        <button className="ui button primal" type="button" onClick={Confirmwithdrawal} name="wit_btn" id="wit_btn">Next <span className="arrow-next">→</span></button>
                                                    </form>
                                                </div>

                                                <div className="ui bottom attached tab segment" data-tab="five">
                                                    <p className="info-selected-pay">To request for withdrawal to <b>Ethereum wallet</b>, please make at least one trading deposit by using selected method.</p>
                                                    <form >
                                                        <div id="error" style={{ fontSize: 'small', color: '#FF0000' }} />
                                                        <label>
                                                            <span>Withdrawal amount: </span>
                                                            <span>USD</span>
                                                            <input type="text" required name="w_amount" onChange={(e) => { setamount(e.target.value) }} placeholder={0.00} size={20} style={{ minWidth: '150px' }} id="amount" onkeypress="javascript:return isNumber(event)" /></label>
                                                        <input type="hidden" required name="method" defaultValue="Ethereum" size={20} style={{ minWidth: '150px' }} />
                                                        <div style={{ height: '10px' }} />
                                                        <label>
                                                            <span>Ethereum wallet: </span>
                                                            <input type="text" required name="w_address" onChange={(e) => { setwallet(e.target.value) }} size={40} style={{ minWidth: '200px' }} />
                                                        </label>
                                                        <button className="ui button primal" type="button" onClick={Confirmwithdrawal} name="wit_btn" id="wit_btn">Next <span className="arrow-next">→</span></button>
                                                    </form>
                                                </div>


                                                <div className="ui bottom attached tab segment active" data-tab="nine">
                                                    <p className="info-selected-pay">Bitcoin is recommended withdrawal method. It provides <b>fastest withdrawal with 0 commission</b>. To request for withdrawal to bitcoin, please confirm you have bitcoin account or create it.</p>
                                                    <div className="mini-withdraw-form" style={{ display: 'inline' }}>
                                                        <div className="input-amount-wrap">
                                                            <form >
                                                                <div id="error" style={{ fontSize: 'small', color: '#FF0000' }} />
                                                                <label>
                                                                    <input type="hidden" required name="method" defaultValue="Bitcoin" size={20} style={{ minWidth: '150px' }} />
                                                                    <span>Withdrawal amount: </span>
                                                                    <span>USD</span>
                                                                    <input type="text" required name="w_amount" onChange={(e) => { setamount(e.target.value) }} placeholder={0.00} size={20} style={{ minWidth: '150px' }} id="amount" onkeypress="javascript:return isNumber(event)" /></label>
                                                                <div style={{ height: '10px' }} />
                                                                <label>
                                                                    <span>Bitcoin wallet: </span>
                                                                    <input type="text" required name="w_address" onChange={(e) => { setwallet(e.target.value) }} defaultValue size={40} style={{ minWidth: '200px' }} />
                                                                </label>
                                                                <button className="ui button primal" type="button" onClick={Confirmwithdrawal} name="wit_btn" id="wit_btn">Next <span className="arrow-next">→</span></button>
                                                            </form>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="after-note">
                                                <b>Please note:</b>
                                                before approving a withdrawal request, you may be required to submit proof
                                                of identity and address of the requester. Withdrawal fees will be
                                                applied, based on type of trading account and acceptable withdrawal
                                                method. Withdrawals are normaly processed, using the same method as
                                                deposit was done. For security reasons, withdrawal requests to ewallets, bank and creditcard accounts, not belonging to a trading account owner are denied. Please refer to terms and conditions for more information.</p>
                                        </div>
                                    </div>
                                </section>
                                <style dangerouslySetInnerHTML={{ __html: " \np1 { \n  text-align: center; \n  font-size: 40px; \n}\np2 { \n  text-align: center; \n  font-size: 30px; \n} \n" }} />
                                <style className="ng-scope" dangerouslySetInnerHTML={{ __html: "\n    .md-dialog-content {\n        color: #333;\n    }\n    .wd-warn {\n        font-size: 14px;\n        font-weight: 400;\n        color:#fff;\n    }\n    .wd-pending {\n        margin: 40px 0;\n    }\n" }} /></div>
                        </div></div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="row clearfix">
                        <div className="float-left">
                            <span className="copy">© 2022 <span data-company>GLOBALFOXTRADE</span>. <span data-company-address>The Stables, Levens Hall LA8 0PB, England, UK</span></span> <a href="/en/policies">Privacy policy</a>
                        </div>
                        <div className="float-right">
                            <ul className="soc-list">
                                <li>Follow us</li>
                                {/*<li>
                    	<div class="footer-media">
		                    <div class="media-icons">
		                        <a href="https://www.facebook.com/GLOBALFOXTRADE"><span class="icon-mso-fb"></span></a>
		                    </div>
		                </div>
                    </li>*/}
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-instagram" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-twitter-square" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                {/*<li><a href="https://www.linkedin.com/company/GLOBALFOXTRADE" class="soc_li"></a></li> 
                    <li><a href="https://twitter.com/GLOBALFOXTRADECom" class="soc_tw"></a></li>
                    <li><a href="https://www.facebook.com/GLOBALFOXTRADECom" class="soc_fb"></a></li>*/}
                                {/*<li><a href="" class="soc_yt"></a></li>*/}
                            </ul>
                        </div>
                    </div>
                </footer>	</div>
            {/* Yandex.Metrika counter */}
            <noscript aria-hidden="false">&lt;div&gt;&lt;img src="https://mc.yandex.ru/watch/38379630" style="position:absolute; left:-9999px;" alt="" /&gt;&lt;/div&gt;</noscript>
            {/* /Yandex.Metrika counter */}
            <noscript aria-hidden="false">
                &lt;div style="display:inline;"&gt;
                &lt;img height="1" width="1" style="border-style:none;" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1057644682/?guid=ON&amp;amp;script=0"/&gt;
                &lt;/div&gt;
            </noscript>
            {/* Global site tag (gtag.js) - Google AdWords: 824992907 */}
            {/*StartofTawk.toScript*/}
            {/*End of Tawk.to Script*/}
            {
                loading && (
                    <div class="preloader js-preloader">
                        <div class="loader loader-inner-1">
                            <div class="loader loader-inner-2">
                                <div class="loader loader-inner-3">
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Withdrawal